import { Image, ImageProps } from '../../../../feature/image/component/Image'
import { observer } from 'mobx-react-lite'
import { useLocalizationStore } from '../../../../feature/localization/store/localization.store'
import DefaultAvatarImage from '../../../../feature/image/asset/default-avatar.jpeg'
import { handlePassedSx } from '../../../../feature/theme/model/Sx'
import { Box, Tooltip } from '@mui/material'
import RemoveModeratorIcon from '@mui/icons-material/RemoveModerator'
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser'

export type AppAvatarProps = Partial<ImageProps> & { showVerfiedMark?: boolean, isVerified?: boolean }

export const AppAvatar = observer((props: AppAvatarProps) => {
  const { translation } = useLocalizationStore()
  const {
    src = DefaultAvatarImage,
    alt = translation.util.avatar,
    outerProps: {
      sx: rootSx = [],
      ...restRootProps
    } = {},
    ...rest
  } = props

  return (
    <Box bgcolor={'white'} padding={5} borderRadius={'50%'}>
      <Image
        {...rest}
        src={src}
        alt={alt}
        outerProps={{
          squareSize: 54,
          sx: [
            styles,
            ...handlePassedSx(rootSx),
          ],
          ...restRootProps,
        }}
      />
      {props.showVerfiedMark && (
        <Tooltip title={props.isVerified ? 'Verifierad' : 'Overifierad'}>
          {props.isVerified ? <VerifiedUserIcon sx={markStyle} /> : <RemoveModeratorIcon sx={markStyle} />}
        </Tooltip>
      )}
    </Box>
  )
})

const styles = {
  borderRadius: '50%',
  overflow: 'hidden',
  // margin: 5,
}

const markStyle = {
  position: 'absolute',
  marginLeft: '90px',
  marginTop: '-70px',
  width: '60px',
  height: '60px',
  color: 'gold',
}
