import { Theme } from '@mui/material'
import { AppLink, AppLinkProps } from '../../analog/molecule/link/AppLink'
import { useLocation } from 'react-router-dom'
import { handlePassedSx } from '../../../../feature/theme/model/Sx'
import { AppListItem } from '../../analog/molecule/list/AppListItem'
import { isPathActive } from '../../../../model/Path'
import { SxHelperStyle } from '../../../../feature/theme/style/sx-helper.style'

export function NavListItem(props: AppLinkProps) {
  const { pathname } = useLocation()
  const { sx = [], isMain = false, ...rest } = props
  const mainPage = props.link ? isMain && props.link.split('/').length > 2 ? props.link.substring(0, props.link.lastIndexOf('/') + 1) : props.link : props.mail

  return (
    <AppListItem
      sx={styles.root}
      selected={isPathActive(pathname, mainPage)}
    >
      <AppLink
        underline={'none'}
        sx={[
          styles.link,
          ...handlePassedSx(sx),
        ]}
        {...rest}
      />
    </AppListItem>
  )
}

// const activeListStyle = {
//   opacity: 0.3,
//   transform: 'translateY(0)',
// }

export const LINK_LABEL_CLASS_NAME = 'link-label'

const styles = {
  root: ({ palette }: Theme) => ({
    ...SxHelperStyle.noIndents,
    [`& .${LINK_LABEL_CLASS_NAME}`]: {
      fontSize: '1em',
      position: 'relative',
      whiteSpace: 'nowrap',
      // '&::before': {
      //   content: '""',
      //   position: 'absolute',
      //   top: '50%',
      //   left: 0,
      //   width: '100%',
      //   height: '50%',
      //   transform: 'translateY(150%)',
      //   transition: 'all 0.2s ease-in-out',
      //   opacity: 0,
      //   background: palette.primary.main,
      // },
    },
    '&:hover': {
      color: `${palette.primary.main} !important`,
      // [`& .${LINK_LABEL_CLASS_NAME}::before`]: activeListStyle,
    },
    '&.Mui-selected': {
      background: 'none',
      color: palette.primary.main,
      // [`& .${LINK_LABEL_CLASS_NAME}`]: {
      //   color: palette.common.black,
      //   fontWeight: 600,
      //   '&::before': activeListStyle,
      // },
    },
  }),
  link: ({ palette }: Theme) => ({
    fontSize: '1em',
    color: palette.common.black,
    textAlign: 'left',
    '&:hover': {
      color: `${palette.primary.main} !important`,
    },
    // '&:has(> .MuiListItem-root)': {
    //   color: `${palette.common.black}`,
    // },
    // '&:has(> .Mui-selected)': {
    //   color: `${palette.primary.main} !important`,
    // },
  }),
}
