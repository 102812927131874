import { applyInterceptors } from './util/axios/interceptors.axios'
import { axiosInstance } from './util/axios/instance.axios'
import { renderApp } from './model/React'
import { AppProvider } from './component/unique/provider/AppProvider'
import { App } from './component/unique/organism/App'

applyInterceptors(axiosInstance)

renderApp(
  <AppProvider>
    <App />
  </AppProvider>,
)
