import { observer } from 'mobx-react-lite'
import { AppBox } from 'src/component/common/analog/molecule/AppBox'
import { Palette } from 'src/feature/theme/model/Palette'
import { Title2 } from 'src/feature/localization/component/atom/Title2'
import { AppIconButton } from 'src/component/common/analog/molecule/button/AppIconButton'
import EditIcon from '@mui/icons-material/Edit'
import { LocalizedString } from 'typesafe-i18n'

export type Title2withEditProps = {
  onEdit: () => void,
  title: LocalizedString | string
}

export const Title2withEdit = observer((props: Title2withEditProps) => {
  return (
    <AppBox sx={styles.titleBox}>
      <Title2 textValue={props.title} />
      <AppIconButton size='small' sx={styles.iconButton} onClick={props.onEdit}><EditIcon fontSize='small' /></AppIconButton>
    </AppBox>
  )
})

const styles = {
  titleBox: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  iconButton: {
    border: 'solid',
    borderWidth: 1,
    borderRadius: '50%',
    borderColor: Palette.HAN_BLUE,
  },
}
