import { Box, Theme } from '@mui/material'
import useEmblaCarousel from 'embla-carousel-react'
import { observer } from 'mobx-react-lite'
import { ChildrenProps } from 'src/model/React'
import { AppBox } from '../AppBox'
import { CarouselProgressBar } from './CarouselprogressBar'
import { CarouselButtons } from './CarouselButtons'

export const AppCarousel = observer(({ children }: ChildrenProps) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({
    breakpoints: {
      '(min-width: 600px)': {
        slidesToScroll: 1,
      },
      '(min-width: 960px)': {
        slidesToScroll: 2,
      },
      '(min-width: 1280px)': {
        slidesToScroll: 3,
      },
    },
  })

  return (
    <Box sx={styles.root}>
      <AppBox sx={styles.carouselContainer} ref={emblaRef}>
        <Box sx={styles.carouselElement}>{children}</Box>
      </AppBox>
      <CarouselProgressBar emblaApi={emblaApi} />
      <CarouselButtons emblaApi={emblaApi} />
    </Box>
  )
})

const styles = {
  root: ({ handleCssUnit }: Theme) => ({
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    gap: handleCssUnit(10),
    cursor: 'pointer',
  }),
  carouselContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  carouselElement: ({ handleCssUnit }: Theme) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: handleCssUnit(5),
    minHeight: '150px',
    minWidth: '150px',
  }),
}
