/* eslint-disable react/function-component-definition,react-hooks/rules-of-hooks */

import Box, { BoxProps } from '@mui/material/Box'
import { handlePassedSx } from '../../../../feature/theme/model/Sx'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { IsTrue } from '../../../../model/Boolean'
import { PaperProps, Theme } from '@mui/material'
import { isStrOrNum } from '../../../../util/helper'
import { CssUnit } from '../../../../feature/theme/model/CssUnit'
import Grid, { GridProps } from '@mui/material/Grid'
import Stack, { StackProps } from '@mui/material/Stack'
import Paper from '@mui/material/Paper'
import { forwardRef, useMemo } from 'react'
import { AnyObject } from '../../../../model/Object'
import LazyLoad, { LazyLoadProps } from 'react-lazyload'
import { useMemoForwarded } from '../../../../hook/useMemoForwarded'
import { typesafe_Includes } from '../../../../model/Array'
import { SxHelperStyle } from '../../../../feature/theme/style/sx-helper.style'
import { Breakpoint } from '@mui/system/createTheme/createBreakpoints'

type FontSize = CssUnit
type Color = string

interface WithStackProps extends StackProps {
  isStack?: boolean
}

interface WithFlexibleProps extends GridProps {
  isFlexible?: boolean
}

type WithLayoutProps = WithStackProps | WithFlexibleProps

interface WithPaperProps extends PaperProps {
  isPaper?: boolean
}

interface WithLazyLoadedProps extends LazyLoadProps {
  isLazyLoaded?: boolean
  innerProps?: AnyObject
}

export type BaseAppBoxProps = BoxProps & WithLayoutProps & WithPaperProps & WithLazyLoadedProps & {
  isShown?: IsTrue
  looksLike?: OverridableComponent<any>
  color?: Color
  fz?: FontSize
  squareSize?: CssUnit
  withOpacity?: boolean
}

export type MaxWidth = Breakpoint | false

export interface ContainerArg {
  maxWidth?: MaxWidth
}

export type ContainerProps = Omit<BaseAppBoxProps, 'maxWidth'> & ContainerArg

export interface WithContainerBaseAppBoxProps extends ContainerProps {
  isContainer?: boolean
}

export type AppBoxProps =
  WithContainerBaseAppBoxProps
  & AnyObject // TODO: remove this!

export const AppBox = forwardRef((props: AppBoxProps, ref) => {
  const { isShown = true } = props

  if (!isShown) {
    return null
  }

  const {
    sx = [],
    isFlexible,
    withOpacity,
    isStack,
    isPaper,
    looksLike: LookedLike = Box, // we use alias here because custom components should be uppercase
    component,
    isContainer,
    isLazyLoaded,
    maxWidth,
    display = component ? undefined : 'flex',
    squareSize,
    color,
    fz,
    ...rest
  } = props

  const isLayout = isFlexible || isStack

  const DefaultComponent = useMemoForwarded({
    Component: Box,
    additionalProps: { component },
    deps: [component],
  })

  const WithPaper = useMemoForwarded({
    Component: Paper,
    additionalProps: { component },
    deps: [component],
  })

  const WithLayout = useMemoForwarded({
    Component: isFlexible ? Grid : Stack,
    additionalProps: { component: isPaper ? WithPaper : component },
    deps: [component, isFlexible, isPaper],
  })

  const WithLazyLoaded = useMemoForwarded({
    Component: Box,
    deps: [component, isLayout, isPaper],
    manageProps: ({ innerProps: { component: innerComponent = null, ...restInnerProps } = {}, ...rest }) => ({
      ...rest,
      component: LazyLoad,
      children: <Box {...restInnerProps} component={isLayout ? WithLayout : isPaper ? WithPaper : innerComponent} />,
    }),
  })

  const WithComponents = useMemo(() => {
    switch (true) {
    case isLazyLoaded:
      return WithLazyLoaded
    case isLayout:
      return WithLayout
    case isPaper:
      return WithPaper
    default:
      return DefaultComponent
    }
  }, [isLazyLoaded, isLayout, isPaper, component])

  const sxArr = [
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    styles({ color, fz, squareSize, withOpacity, maxWidth, isContainer }),
    ...handlePassedSx(sx),
  ] as const

  return (
    <Box
      display={display}
      {...rest}
      sx={sxArr}
      component={WithComponents}
      ref={ref}
    />
  )
})

AppBox.displayName = 'AppBox'

const styles = ({ color, fz, squareSize, withOpacity, isContainer, maxWidth = 'xl' }: AppBoxProps) => ({ handleCssUnit, getSquareSize, breakpoints }: Theme) => ({
  ...(withOpacity && { opacity: '0.5' }),
  ...(color && { color }),
  ...(squareSize && getSquareSize(squareSize)!),
  ...(isStrOrNum(fz) && { fontSize: handleCssUnit(fz) }),
  ...(isContainer && {
    ...SxHelperStyle.fullSize,
    ...SxHelperStyle.flexColumn,
    paddingLeft: {
      xs: 24,
      md: 32,
      lg: 56,
    },
    paddingRight: {
      xs: 24,
      md: 32,
      lg: 56,
    },
    margin: '0 auto',
    maxWidth: typesafe_Includes(breakpoints.keys, maxWidth) ? breakpoints.values[maxWidth] : !maxWidth ? 'unset' : maxWidth,
  }),
})
