import { AppBox } from 'src/component/common/analog/molecule/AppBox'
import { Title1 } from '../../../localization/component/atom/Title1'
import { Word } from '../../../localization/component/atom/Word'

export type PageTitleProps = {
  category: string
  count: number
}

export function CategoryTitle({ category, count }: PageTitleProps) {
  if (count > 0) {
    return (<AppBox>
      <Title1
        mt={0}
        mx={0}
        mb={'0.571em'}
        textValue={category}
      />
      <Word sx={styles.pill}>{count}</Word>
    </AppBox>)
  }

  return (
    <Title1
      mt={0}
      mx={0}
      mb={'0.571em'}
      textValue={category}
    />
  )
}

const styles = {
  pill: {
    margin: '10px',
    maxHeight: '38px',
    borderRadius: '1rem',
    border: '3px solid #fff',
    padding: '5px 10px',
    backgroundColor: '#c8b7f8',
    color: '#fff',
  },
}