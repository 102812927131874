/* eslint-disable no-useless-catch,@typescript-eslint/no-non-null-assertion,@typescript-eslint/no-non-null-asserted-optional-chain */
import { YohrsUser } from '../model/User'
import { createStore } from '../../../util/mobx/generic-store/store-creator'
import DefaultAvatarImage from 'src/feature/image/asset/default-avatar.jpeg'
import { GenericStoreAsyncMethod } from '../../../util/mobx/generic-store/decorator/async-method.decorator'
import { GenericStore } from '../../../util/mobx/generic-store/generic.store'
import { getActiveSubscriptions, getCurrentUser, getOrganisation, getPositionUser, getSiteMap, postPositionUser, updateCurrentUser } from '../api/user'
import { concatStrings } from '../../../model/String'
//import { getBase64FromUrl } from '../../../model/File'
import { dayJsInstance } from '../../localization/model/Time'
import { SiteMap } from '../model/SiteMap'
import { Organisation } from '../model/Organisation'
import { PositionUser } from '../model/PositionUser'
import { API_BASE_URL } from 'src/util/constant'

export class UserStore extends GenericStore {
  userData!: YohrsUser | null
  avatar!: undefined | string
  subscriptions?: string[] | undefined
  sitemap?: SiteMap | undefined
  organisation?: Organisation | undefined
  positions?: PositionUser[] | undefined

  constructor() {
    super('UserStore')

    super.observe(this)
    this.reset()
    super.persist({ encrypt: false })
  }

  hasSubscriptionPlan(subscriptionName: string[] | undefined): boolean {
    if (this.hasPrepaidLearning() || this.hasLearningV2()) {
      return true
    }

    if (!this.subscriptions || !subscriptionName) {
      return false
    }

    return this.subscriptions!.some(s => subscriptionName.some(ss => ss === s))
  }

  hasActiveTrial(): boolean {
    return this.daysLeftInTrial() > 0
  }

  daysLeftInTrial(): number {
    if (!this.userData || this.userData.freeTrial === '') {
      return 0
    }

    const endDate = new Date(`${this.userData.freeTrial}T00:00:00.000Z`)
    const timeLeft = endDate.getTime() - new Date().getTime()

    return Math.ceil(timeLeft / (1000 * 3600 * 24)) + 1
  }

  hasPrepaidLearning(): boolean {
    return (this.sitemap?.modules.some(m => m.key === 'eLearning' && m.level === 'full') || false) || (this.sitemap?.modules.some(m => m.key === 'eLearningV2') && this.userData?.organisation !== undefined || false)
  }

  hasLearningV2(): boolean {
    return this.sitemap?.modules.some(m => m.key === 'eLearningV2') && this.userData?.organisation !== undefined || false
  }

  getCompanyLogoUrl(id: string): string {
    return `chrome://new-tab-page/icons/google_logo.svg?${id}`
  }

  get hasTrial(): boolean {
    const freeTrialTime = this.userData?.freeTrial?.length

    return Boolean(freeTrialTime) && dayJsInstance(this.userData?.freeTrial) > dayJsInstance()
  }

  get fullName() {
    return concatStrings([this.userData?.firstName, this.userData?.lastName])
  }

  reset() {
    this.userData = null
    this.sitemap = undefined
    this.avatar = undefined
    this.subscriptions = undefined
    this.organisation = undefined
    this.positions = undefined
  }

  clearSubscriptions() {
    this.subscriptions = undefined
  }

  async handleUpdateYohrsUser(user: Partial<YohrsUser>) {
    const response = await updateCurrentUser(user)
    this.userData = response

    return response
  }

  async handleSetAvatar() {
    if (!this.userData?.avatarUrl) {
      this.avatar = DefaultAvatarImage
    } else {
      this.avatar = API_BASE_URL + this.userData.avatarUrl
    }
  }

  @GenericStoreAsyncMethod()
  async handleGetUser() {
    this.userData = await getCurrentUser()
  }

  @GenericStoreAsyncMethod()
  async handleUpdateSubscriptions() {
    this.subscriptions = await getActiveSubscriptions()
  }

  @GenericStoreAsyncMethod()
  async handleGetOrganisation() {
    this.organisation = await getOrganisation()
  }

  async handleUpdateSiteMap() {
    this.sitemap = await getSiteMap()
  }

  @GenericStoreAsyncMethod()
  async handleUpdatePositions() {
    this.positions = await getPositionUser()
  }

  @GenericStoreAsyncMethod()
  async savePositions(positions: PositionUser[]) {
    await postPositionUser(positions)
  }
}

export const {
  storeInstance: userStoreInstance,
  useStore: useUserStore,
  StoreProvider: UserStoreProvider,
} = createStore(new UserStore())
