import { useEffect } from 'react'

export type AppScrollDetectionProps = {
  onBottom?: () => void
}

export function AppScrollDetection(props: AppScrollDetectionProps) {
  useEffect(() => {
    const onScroll = async (event: Event) => {
      if (event.currentTarget) {
        const rootDiv = event.currentTarget as HTMLElement
        const hasScroll = rootDiv.scrollHeight !== rootDiv.clientHeight
        const bottom = rootDiv.scrollHeight - rootDiv.scrollTop === rootDiv.clientHeight
        
        if (bottom && hasScroll && props.onBottom) {
          props.onBottom()
        }
      }
    }

    const win: Window = window
    win.document.body.children.namedItem('root')!.addEventListener('scroll', onScroll)

    return () => {
      window.document.body.children.namedItem('root')!.removeEventListener('scroll', onScroll)
    }
  }, [])

  return (null)
}
