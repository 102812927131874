import { AppRoutePathModel, RoutePathConfig, StaticRoutePath } from '../model/Route'
import { DYNAMIC_ROUTE_START } from './router.constant'
import { Id } from '../../../model/Id'

export const appHomePath = '/'

function getPath(subPath = '') {
  return appHomePath + subPath
}

const prefixes = {
  onBoarding: getPath('on-boarding'),
  auth: getPath('auth'),
  profile: getPath('profile'),
  knowledge: getPath('knowledge'),
  eLearning: getPath('e-learning'),
  get subscription() {
    return `${this.profile}/subscription`
  },
  get payment() {
    return `${this.profile}/payment`
  },
  get paymentHistory() {
    return `${this.payment}/history`
  },
  get faq() {
    return `${this.knowledge}/faq`
  },
  controlpanel: getPath('controlpanel'),
}

export const appRoutePath: AppRoutePathModel = {
  // forum
  forum: getPath('forum'),

  // villkor
  villkor: getPath('villkor'),

  // payroll
  payroll: getPath('payroll'),

  // maintenance
  maintenance: getPath('maintenance'),

  // e-learning
  eLearning: getPath('e-learning'),
  platformArticle(id: string) {
    return `${this.eLearning}/${id}`
  },
  learning: getPath('learning'),
  learningCategory(key: string) {
    return `${this.learning}/${key}`
  },
  course: getPath('course'),
  learningCourse(courseId: string) {
    return `${this.course}/${courseId}`
  },

  // company-page
  companyPage: getPath('company'),
  companyPageEditItem: getPath('company-edit'),

  // blog
  blog: getPath('blog'),
  blogArticle(id: string) {
    return `${this.blog}/${id}`
  },

  // knowledge
  faq: prefixes.faq,
  faqGroup(id: Id) {
    return `${this.faq}/${id}`
  },
  get additionalServices() {
    return `${prefixes.knowledge}/additional-services`
  },

  // auth
  get forgotPassword() {
    return `${prefixes.auth}/forgot-password`
  },
  get resetPassword() {
    return `${prefixes.auth}/reset-password`
  },
  get signIn() {
    return `${prefixes.auth}/sign-in`
  },
  get verifyAccount() {
    return `${prefixes.auth}/verify-account`
  },
  get ssoVerify() {
    return `${prefixes.auth}/sso-verify`
  },

  // profile
  get profileInfo() {
    return `${prefixes.profile}/info`
  },
  get editProfile() {
    return `${prefixes.profile}/edit`
  },
  get editPositions() {
    return `${prefixes.profile}/positions`
  },
  get coursePlan() {
    return `${prefixes.profile}/courses`
  },
  subscription: prefixes.subscription,
  paymentHistory: prefixes.paymentHistory,
  get activateLearning() {
    return `${prefixes.subscription}/activate-learning`
  },

  // on boarding
  get signUp() {
    return `${prefixes.onBoarding}/sign-up`
  },

  // B2B
  login(id: string) {
    return `login/${id}`
  },
  get controlpanelOverview() {
    return `${prefixes.controlpanel}/overview`
  },
  get controlpanelUsers() {
    return `${prefixes.controlpanel}/users`
  },
  get controlpanelModules() {
    return `${prefixes.controlpanel}/modules`
  },
  get controlpanelOrganisation() {
    return `${prefixes.controlpanel}/organisation`
  },
  get controlpanelSystem() {
    return `${prefixes.controlpanel}/system`
  },
} as const

export function getDynamicRouteConfigPath(path: StaticRoutePath) {
  return `${DYNAMIC_ROUTE_START}${path}`
}

export const routePathConfig: RoutePathConfig = {
  ...appRoutePath,
  blogArticle: appRoutePath.blogArticle(getDynamicRouteConfigPath('id')),
  platformArticle: appRoutePath.platformArticle(getDynamicRouteConfigPath('id')),
  learningCategory: appRoutePath.learningCategory(getDynamicRouteConfigPath('key')),
  learningCourse: appRoutePath.learningCourse(getDynamicRouteConfigPath('courseId')),
  faqGroup: appRoutePath.faqGroup(getDynamicRouteConfigPath('faqGroupName')),
  login: appRoutePath.login(getDynamicRouteConfigPath('id')),
} as const
