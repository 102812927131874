import { AppTextField } from 'src/feature/form/component/analog/AppTextField'
import { Image } from '../../../../../src/feature/image/component/Image'
import SearchIcon from 'src/feature/image/asset/icon/search.svg'
import { observer } from 'mobx-react-lite'

export type AppSearchBarProps = {
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void
}

export const AppSearchBar = observer(({ onChange, onFocus }: AppSearchBarProps) => {
  return (
    <AppTextField
      placeholder={'Sök...'}
      InputProps={{
        sx: styles.field,
        endAdornment: (
          <Image
            src={SearchIcon}
            alt='search'
          />
        ),
      }}
      onChange={onChange}
      onFocus={onFocus}
    />
  )
})

const styles = {
  field: {
    py: '0.7em',
  },
}