import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useELearningStore } from '../../store/e-learning.store'
import { CircularProgress, Collapse, Theme } from '@mui/material'
import { AppBox } from 'src/component/common/analog/molecule/AppBox'
import { CourseListPresentationItem } from './CourseListPresentationItem'
import { Caption } from 'src/feature/localization/component/atom/Caption'
import { useLocalizationStore } from 'src/feature/localization/store/localization.store'
import { debounce } from 'lodash'
import { AppSearchBar } from 'src/component/common/analog/molecule/AppSearchBar'
import { PageTitle } from 'src/feature/localization/component/atom/PageTitle'
import { CategoryItem } from './CategoryItem'
import { AppScrollDetection } from 'src/component/common/analog/molecule/AppScrollDetection'

export type LearningSearchProps = {
  isSearching: boolean
}

export const LearningSearch = observer((props: LearningSearchProps) => {
  const eLearningStore = useELearningStore()
  const { translation } = useLocalizationStore()
  const texts = translation.pages.eLearning
  const { isLoading } = eLearningStore.getGenericFieldsState('search')
  const [currentSearch, setCurrentSearch] = useState('')
  const pageSize = 50

  useEffect(() => {
    eLearningStore.clearSearch()
  }, [])

  const handleSearch = debounce(async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length > 2) {
      setCurrentSearch(event.target.value)
      await eLearningStore.search(event.target.value, 0, pageSize)
    } else if (event.target.value.length === 0) {
      setCurrentSearch('')
      eLearningStore.clearSearch()
    }
  }, 750)

  const extendSearchResults = async () => {
    if (eLearningStore.searchList) {
      await eLearningStore.appendSearch(currentSearch, eLearningStore.searchList.length, pageSize)
    }
  }

  return (
    <AppBox sx={styles.mainSection} >
      <AppBox sx={styles.upperSection}>
        <PageTitle textValue={'Looking for a specific course?'} />
        <AppBox sx={styles.searchbarContainer}>
          <AppSearchBar onChange={handleSearch} />
          {isLoading && <CircularProgress size={50} />}
        </AppBox>
      </AppBox>
      {props.isSearching && <AppBox sx={styles.lowerSection}>
        {eLearningStore.searchList && eLearningStore.searchList.map(i => <CourseListPresentationItem item={i} key={i.id} />)}
        {!isLoading && eLearningStore.searchList && eLearningStore.searchList.length === 0 && <Caption textValue={texts.noSearchHit} />}
        <AppScrollDetection onBottom={extendSearchResults} />
      </AppBox>}
      <Collapse in={props.isSearching && !eLearningStore.searchList}>
        <AppBox sx={styles.learningCategory}>
          {eLearningStore.categories && eLearningStore.categories.map((category, index) => <CategoryItem key={category.id} item={category} index={index} />)}
        </AppBox>
      </Collapse>
    </AppBox>
  )
})

const styles = {
  mainSection: {
    flexDirection: 'column',
  },
  upperSection: ({ handleCssUnit }: Theme) => ({
    gap: handleCssUnit(5),
    flexDirection: 'column',
    alignItems: 'center',
  }),
  lowerSection: ({ handleCssUnit }: Theme) => ({
    display: 'flex',
    gap: handleCssUnit(10),
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
    maxWidth: '100%',
    minWidth: '100%',
  }),
  learningCategory: ({ handleCssUnit }: Theme) => ({
    display: 'flex',
    gap: handleCssUnit(10),
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
    maxWidth: '100%',
    minWidth: '100%',
  }),
  searchbarContainer: {
    display: 'flex',
    gap: '1rem',
    width: { lg: '30rem', md: '25rem', sm: '20rem' },
  },
}