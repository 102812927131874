import { Backdrop, Button, ButtonGroup } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { AppBox } from 'src/component/common/analog/molecule/AppBox'
import { useOverlayStore } from '../store/overlay.store'
import { OverlayContent } from './OverlayContent'
import { OverlayOverview } from './OverlayOverview'
import { useUserStore } from 'src/feature/user/store/user.store'

export const GlobalOverlay = observer(() => {
  const userStore = useUserStore()
  const { showOverlay, closeOverlay } = useOverlayStore()
  const [page, setPage] = useState(1)
  const [previousPage, setPreviousPage] = useState(0)
  const pages = 3

  if (!showOverlay || userStore.userData?.organisation) {
    return null
  }

  const trySetPage = (np: number) => {
    const newPage = Math.min(pages, Math.max(1, np))

    if (newPage !== page) {
      setPreviousPage(page)
      setPage(newPage)
    }
  }

  const handleNext = () => page === pages ? closeOverlay() : trySetPage(page + 1)
  
  return (
    <Backdrop
      sx={styles.backdrop}
      open={true}>
      <AppBox sx={styles.container}>
        <OverlayOverview currentPage={page} setPage={trySetPage} totalPages={pages} />
        <OverlayContent page={page} setPage={trySetPage} previousPage={previousPage} />
        <ButtonGroup variant="text">
          <Button onClick={closeOverlay} sx={styles.buttons}>Stäng</Button>
          <Button onClick={handleNext} sx={styles.buttons}>{page === 3 ? 'Nu kör vi' : 'Nästa'}</Button>
        </ButtonGroup>
      </AppBox>
    </Backdrop>
  )
})

const styles = {
  backdrop: {
    color: '#fff',
    zIndex: (theme: Theme) => theme.zIndex.drawer + 1,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '20px',
    overflow: 'hidden',
    maxWidth: '98%',
    width: '360px',
    padding: '20px 20px 0 20px',
    backgroundColor: 'rgb(200,184,249)',
    background: 'linear-gradient(152deg, rgba(200,184,249,1) 0%, rgba(200,184,249,1) 35%, rgba(82,27,238,1) 100%)',
  },
  buttons: {
    color: '#fff',
  },
}