import { AppBox } from '../../../../component/common/analog/molecule/AppBox'
import { observer } from 'mobx-react-lite'
import { useLocalizationStore } from 'src/feature/localization/store/localization.store'
import { Title2 } from 'src/feature/localization/component/atom/Title2'
import { PlatformList } from './PlatformList'

export const OtherPartners = observer(() => {
  const { translation } = useLocalizationStore()
  const texts = translation.pages.eLearning

  return (
    <AppBox sx={styles.container}>
      <Title2>{texts.otherPartners()}</Title2>
      <PlatformList excludePayed={true} compactList={true} />
    </AppBox>
  )
})

const styles = {
  container: {
    width: '100%',
    flexDirection: 'column',
  },
}
