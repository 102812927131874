import { useBreakpoints } from '../../../../theme/hook/useBreakpoints'
import { MainLinks } from '../organism/MainLinks'
import { ProfileLink } from '../organism/ProfileLink'
import { observer } from 'mobx-react-lite'
import { DesktopHeader } from '../template/DesktopHeader'
import { MobileHeader } from '../template/MobileHeader'
import { useLocation } from 'react-router-dom'
// import { ThemeButton } from 'src/feature/theme/component/provider/ThemeButton'

export const WithSubscriptionHeader = observer(() => {
  const { isMd } = useBreakpoints()
  const { pathname } = useLocation()

  return (
    <>
      {isMd ? (
        <MobileHeader pathname={pathname} >
          <MainLinks />
          {/* <ThemeButton /> */}
          <ProfileLink />
        </MobileHeader>
      ) : (
        <DesktopHeader listProps={{ spacing: 72 }}>
          <MainLinks />
          {/* <ThemeButton /> */}
          <ProfileLink />
        </DesktopHeader>
      )}
    </>
  )
})
