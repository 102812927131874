import { LinkPath } from './Link'
import { isLength } from '../util/helper'

export type PathToPublicFolder = string

export function getPathVariants(path: LinkPath) {
  return [path, `${path}/`]
}

export function isPathExactActive(currentPath: LinkPath, givenPath: LinkPath) {
  return getPathVariants(givenPath).includes(currentPath)
}

export function isPathActive(currentPath: LinkPath, givenPath: LinkPath) {
  return isLength(givenPath) && currentPath.includes(givenPath)
}

export function isPathStartsWith(currentPath: LinkPath, givenPath: LinkPath) {
  return currentPath.startsWith(givenPath)
}

export function getSearchQuery(name: string): string {
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop: string) => searchParams.get(prop),
  })

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return params[name]
}
