import { observer } from 'mobx-react-lite'
import { Card, CardContent, CardMedia } from '@mui/material'
import { useELearningStore } from '../../store/e-learning.store'
import { Title3 } from 'src/feature/localization/component/atom/Title3'
import { Paragraph } from 'src/feature/localization/component/atom/Paragraph'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import FavoriteIcon from '@mui/icons-material/Favorite'
import { AppIconButton } from 'src/component/common/analog/molecule/button/AppIconButton'
import { useNavigate } from 'react-router-dom'
import { appRoutePath } from 'src/feature/router/util/app-route-path'
import { CourseListItem } from '../../model/ELearningCourseListItem'
import { AppBox } from 'src/component/common/analog/molecule/AppBox'

export type CourseListPresentationItemProps = {
  item: CourseListItem
}

export const CourseListPresentationItem = observer(({ item }: CourseListPresentationItemProps) => {
  const eLearningStore = useELearningStore()
  const navigate = useNavigate()

  const toggleFavorite = async (e: React.FormEvent<HTMLFormElement>, id: string) => {
    e.stopPropagation()
    await eLearningStore.toggleFavoriteCourse(id)
    await eLearningStore.updatePersonalCoursePlan()
  }

  const tagRegEx = /<([/|\w]+)>/gm

  return (<Card sx={styles.card} key={item.id} onClick={() => navigate(appRoutePath.learningCourse(item.id))}>
    <CardMedia
      component="img"
      alt="course icon/image"
      height="230"
      width="230"
      image={item.imageUrl}
    />
    <CardContent>
      <AppBox sx={styles.actions}>
        <Title3 textValue={item.title} gutterBottom />
        <AppIconButton onClick={(e: React.FormEvent<HTMLFormElement>) => toggleFavorite(e, item.id)} sx={styles.iconButton}>{item.isFavorite ? <FavoriteIcon fontSize='medium' /> : <FavoriteBorderIcon fontSize='medium' />}</AppIconButton>
      </AppBox>
      <Paragraph textValue={item.description.replace(tagRegEx, ' ').trim()} sx={styles.textBox} />
    </CardContent>
  </Card>)
})

const styles = {
  card: {
    maxWidth: 260,
    minWidth: 260,
    cursor: 'pointer',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  textBox: {
    cursor: 'pointer',
    position: 'relative',
    overflow: 'hidden',
    minHeight: '75px', // Line-height 18.75 * 4
    maxHeight: '95px',
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      right: 0,
      height: '18.75px',
      width: '100%',
      background: 'linear-gradient(90deg, transparent, rgb(250, 252, 254))',
    },
  },
  iconButton: {
    borderWidth: 0,
    padding: 0,
    marginLeft: '5px',
  },
  actions: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: '5px',
  },
}
