import { PageTitle } from '../../localization/component/atom/PageTitle'
import { useLocalizationStore } from '../../localization/store/localization.store'
import { observer } from 'mobx-react-lite'
import { PlatformList } from '../component/organism/PlatformList'
import Box from '@mui/material/Box'

export const PlatformPage = observer(() => {
  const { translation } = useLocalizationStore()
  const { eLearning } = translation.pages

  return (
    <>
      <PageTitle textValue={eLearning.hrefLink({ emoji: '📖' })} />
      <Box width={'100^'} display={'flex'}
        justifyContent={'flex-end'}>
      </Box>
      <PlatformList />
    </>
  )
})
