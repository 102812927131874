import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material'
import { Box } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { AppBox } from 'src/component/common/analog/molecule/AppBox'
import { ProductOverlayStep } from './ProductOverlayStep'
import { SlipAnimation } from './SlipAnimation'
import Step1 from '../assets/step1.gif'
import Step2 from '../assets/step2.gif'
import Step3 from '../assets/step3.gif'

export type OverlayContentProps = {
  page: number,
  setPage: (page: number) => void,
  previousPage: number,
}

// https://codepen.io/tomquas/pen/gEJdxR
export const OverlayContent = observer((props: OverlayContentProps) => {
  const { page, previousPage } = props

  return (
    <AppBox sg={styles.box}>
      <ArrowBackIos sx={ { ...styles.navigate, opacity: (page !== 1 ? 1 : 0) } } onClick={() => props.setPage(page - 1)} />
      <Box sx={styles.containtContainer}>
        <SlipAnimation page={1} current={page} previousPage={previousPage} >
          <ProductOverlayStep title='' subTitle='Optimera din karriär' icon={Step1} body='På YOHRS finns alla verktyg du behöver för att styra din karriär i den riktning du vill. Tillsammans tar vi nästa steg!' />
        </SlipAnimation>
        <SlipAnimation page={2} current={page} previousPage={previousPage} >
          <ProductOverlayStep title='Kunskap' subTitle='Lärande för livet' icon={Step2} body='Genom våra partners finns kunskapen du behöver i ett format som passar dig. Alltid med certifikat för att visa dina grymma framsteg!' />
        </SlipAnimation>
        <SlipAnimation page={3} current={page} previousPage={previousPage} >
          <ProductOverlayStep title='Connect' subTitle='' icon={Step3} body='Hör gärna av dig om du har du frågor eller funderingar kring vårt utbud av kurser. Vi utvecklar tjänsten tillsammans!' />
        </SlipAnimation>
      </Box>
      <ArrowForwardIos sx={ { ...styles.navigate, opacity: (page !== 3 ? 1 : 0) } } onClick={() => props.setPage(page + 1)} />
    </AppBox>
  )
})

const styles = {
  box: {
    display: 'flex',
    justifyContent: 'space-between',
    wdith: '100%',
    alignItems: 'center',
  },
  containtContainer: {
    position: 'relative',
    height: '470px',
    width: '282px',
  },
  navigate: {
    position: 'relative',
    margin: 'auto 0',
    padding: '0 -20px',
  },
}