import { AppBox } from '../../../../component/common/analog/molecule/AppBox'
import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Theme } from '@mui/material'
import { useELearningStore } from '../../store/e-learning.store'
import { AppProgressLine } from 'src/component/common/analog/molecule/loader/AppProgressLine'
import { Caption } from 'src/feature/localization/component/atom/Caption'
import { useLocalizationStore } from 'src/feature/localization/store/localization.store'
import { CourseListPresentationItem } from './CourseListPresentationItem'
import { AppPaper } from 'src/component/common/analog/molecule/AppPaper'
import { CategoryTitle } from './CategoryTitle'
import { AppScrollDetection } from 'src/component/common/analog/molecule/AppScrollDetection'

export type CourseListProps = {
  categoryKey: string
  categoryTitle: string
}

export const CourseList = observer((props: CourseListProps) => {
  const eLearningStore = useELearningStore()
  const { translation } = useLocalizationStore()
  const texts = translation.pages.eLearning
  const { isLoading } = eLearningStore.getGenericFieldsState('getCoursesByCategory')
  const pageSize = 50

  useEffect(() => {
    eLearningStore.getCoursesByCategory(props.categoryKey, 0, pageSize)
  }, [])

  const extendResults = async () => {
    if (eLearningStore.courseList) {
      await eLearningStore.appendCoursesByCategory(props.categoryKey, eLearningStore.courseList.length, pageSize)
    }
  }

  return (
    <>
      <CategoryTitle category={props.categoryTitle} count={eLearningStore.courseCount || 0} />
      <AppPaper p={24}>
        <AppBox sx={styles.root}>
          <AppProgressLine isShowing={isLoading} />
          {!isLoading && eLearningStore.courseList && eLearningStore.courseList.map(i => <CourseListPresentationItem item={i} key={i.id} />)}
          {!isLoading && !eLearningStore.courseList && <Caption textValue={texts.missingCourse} />}
          <AppScrollDetection onBottom={extendResults} />
        </AppBox>
      </AppPaper>
    </>
  )
})

const styles = {
  root: ({ handleCssUnit }: Theme) => ({
    display: 'flex',
    gap: handleCssUnit(10),
    flexDirection: 'row',
    flexWrap: 'wrap',
    maxWidth: '100%',
    minWidth: '100%',
  }),
}
