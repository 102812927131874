import { Box, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'

export type ProductOverlayStepProps = {
  title: string,
  subTitle: string,
  body: string,
  icon: string,
}

export const ProductOverlayStep = observer((props: ProductOverlayStepProps) => {
  return (
    <Box>
      <Box sx={ { ...styles.mediaRing } }>
        <Box sx={ { ...styles.mediaHolder } }>
          <img src={props.icon} style={ { ...styles.media } } />
        </Box>
      </Box>
      <Typography sx={styles.text} variant='h3'>{props.title}<br />{props.subTitle}<br /><br /></Typography>
      <Typography sx={styles.text} variant='body2'>
        {props.body}
      </Typography>
    </Box>
  )
})

const styles = {
  media: {
    borderRadius: '36px',
    height: '102px',
    width: '102px',
  },
  mediaHolder: {
    background: 'rgba(255, 255, 255, 1)',
    height: '122px',
    borderRadius: '61px',
    width: '122px',
    padding: '10px',
  },
  mediaRing: {
    background: 'rgba(255, 255, 255, 0.25)',
    borderRadius: '66px',
    margin: '32px auto',
    height: '132px',
    width: '132px',
    padding: '5px',
  },
  logo: {
    marginTop: 20,
    opacity: 1,
    transform: 'translateY(-60px)',
    transition: 'opacity 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1)',
    width: 80,
  },
  text: {
    textAlign: 'center',
  },
}